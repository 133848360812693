import React, { useState, useEffect, useCallback } from 'react';
import { Text, Box } from '@bighealth/react-limbix-ui';

import {
  PrimaryButton,
  SecondaryButton,
} from '../Modal.styles';

import { Warning } from '@/icons';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { usePreventNavigation } from '@/hooks/redux';

type Props = {
  payload: ModalPayloadType['IDLE_WARNING'];
  onClose: () => void;
};
function IdleWarning(props: Props) {
  const {
    onClose,
    payload,
  } = props;

  const {
    countdownDuration,
    stayActive,
    isLeader,
    pause,
    onLogout,
  } = payload;

  const [counter, setCounter] = useState(countdownDuration / 1000);
  const [, { setPreventNavigation }] = usePreventNavigation();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (counter > 0) {
      timeout = setTimeout(() => setCounter(counter - 1), 1000);
    } else if (!isLeader()) {
      pause();
    } else {
      setPreventNavigation({ isBlocked: false });
      onLogout();
    }
    return () => clearTimeout(timeout);
  }, [counter]);

  const closeAndStayActive = useCallback(() => {
    onClose();
    stayActive();
  }, []);

  const closeAndLogout = useCallback(() => {
    onClose();
    onLogout();
  }, []);

  return (
    <Box width="425px" padding="32px" position="relative">
      <Box display="inline-flex">
        <Box margin="0px 10px">
          <Warning height="24px" width="24px" stroke="#DF5F30" />
        </Box>
        <Box display="block">
          <Box marginBottom="8px" display="inline-flex">
            <Text fontWeight="700" fontSize="18px">
              Your session will expire in
            </Text>
            <Text fontWeight="700" fontSize="18px" color="#DF5F30" margin="0 4px">
              {counter}
            </Text>
            <Text fontWeight="700" fontSize="18px">
              seconds
            </Text>
          </Box>
          <Text fontSize="16px" marginBottom="8px">
            To protect the privacy of your patients, we’ll automatically end your session after
            10 minutes of inactivity.
          </Text>
          <Box display="flex" marginTop="24px">
            <Box margin="0 0 0 auto" display="inline-flex">
              <SecondaryButton onClick={closeAndLogout}>
                Log out
              </SecondaryButton>
              <PrimaryButton onClick={closeAndStayActive}>
                Stay signed in
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default IdleWarning;
