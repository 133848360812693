import { Tooltip } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import React from 'react';

type Props = {
    title?: string;
}

function InfoToolTip(props: Props) {
  const { title } = props;
  return (
    <Tooltip
      title={title}
      placement="top"
      color="primary"
      arrow
    >
      <InfoRounded fontSize="small" color="disabled" />
    </Tooltip>
  );
}

export default InfoToolTip;
