import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material/styles';
// this is done so that material ui plays nice with StrictMode
import type { } from '@mui/lab/themeAugmentation';

// A custom theme for this app

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Proxima Nova',
    button: { textTransform: 'none' },
  },
  components: {
    MuiDrawer: { styleOverrides: { root: { zIndex: 1202 } } },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#353232',
          boxShadow: `-4px -4px 8px rgba(0, 0, 0, 0.06),
                      4px -4px 8px rgba(0, 0, 0, 0.06),
                      -4px 4px 8px rgba(0, 0, 0, 0.06),
                      4px 4px 8px rgba(0, 0, 0, 0.06)`,
          overflow: 'visible',
          maxWidth: '250px',
        },
        arrow: { color: '#353232' },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&$completed': { color: '#089a7e' },
          '&$active': {
            color: '#089a7e',
          },
        },
        active: {},
        completed: {},
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: '#EFFBF8',
          color: '#022A23',
          '& $icon': {
            color: '#00B894',
          },
        },
        standardError: {
          backgroundColor: '#FFFAF8',
          color: '#33160B',
          '& $icon': {
            color: '#DF5F30',
          },
        },
        standardInfo: {
          backgroundColor: '#F3F9FF',
          color: '#031D37',
          '& $icon': {
            color: '#1E90FF',
          },
        },
        standardWarning: {
          backgroundColor: '#FEFBF2',
          color: '#372A04',
          '& $icon': {
            color: '#EAAF00',
          },
        },
      },
    },
  },
  mixins: {},
  palette: {
    mode: 'light',
    primary: {
      main: '#1F2E81',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#ff1744',
    },
    background: {
      default: '#fff',
    },
    info: {
      main: '#979191',
    },
  },
});

export default theme;
