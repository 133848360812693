import React from 'react';
import {
  Snackbar, SnackbarOrigin, Alert, AlertTitle,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Text } from '@bighealth/react-limbix-ui';

import { RootState } from '@/store';
import { useActions } from '@/hooks/redux';

function Toast() {
  const { toast: { hideToast } } = useActions();
  const visible = useSelector((state: RootState) => state.toast.visible);
  const payload = useSelector((state: RootState) => state.toast.payload);

  const vertical:SnackbarOrigin['vertical'] = payload?.verticalPosition ?? 'bottom';
  const horizontal:SnackbarOrigin['horizontal'] = payload?.horizontalPosition ?? 'center';

  if (!visible) {
    return null;
  }
  const title = payload.title
    ?? (
      <AlertTitle>
        {payload.title}
      </AlertTitle>
    );

  return (
    <Snackbar
      open={visible}
      autoHideDuration={10000}
      onClose={hideToast as () => void}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert severity={payload.severity} onClose={hideToast as () => void}>
        <div>
          <Text fontSize="14px" fontWeight="500">
            {title}
          </Text>
        </div>
        {payload.message}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
