/* eslint-disable max-len */
import React, { SVGProps } from 'react';

function Dashboard(props: Partial<SVGProps<SVGSVGElement>>) {
  const { width, height, stroke } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path d="M2.0559 11.5886L0.735876 12.5H2.34H11.66H13.2641L11.9441 11.5886C11.4614 11.2553 10.6862 10.9243 9.82418 10.6769C8.95466 10.4274 7.95234 10.25 7 10.25C6.04766 10.25 5.04534 10.4274 4.17582 10.6769C3.31375 10.9243 2.53858 11.2553 2.0559 11.5886ZM0.5 12.25C0.5 11.8508 0.695581 11.4647 1.10396 11.0874C1.51582 10.707 2.11023 10.37 2.8084 10.0904C4.20553 9.53084 5.89232 9.25 7 9.25C8.10768 9.25 9.79447 9.53084 11.1916 10.0904C11.8898 10.37 12.4842 10.707 12.896 11.0874C13.3044 11.4647 13.5 11.8508 13.5 12.25V13.5H0.5V12.25ZM10 3.5C10 5.15386 8.65386 6.5 7 6.5C5.34614 6.5 4 5.15386 4 3.5C4 1.84614 5.34614 0.5 7 0.5C8.65386 0.5 10 1.84614 10 3.5ZM9 3.5C9 2.39386 8.10614 1.5 7 1.5C5.89386 1.5 5 2.39386 5 3.5C5 4.60614 5.89386 5.5 7 5.5C8.10614 5.5 9 4.60614 9 3.5ZM16.5 12.25C16.5 11.1995 16.1433 10.3174 15.575 9.58412C16.3246 9.76212 17.077 10.0095 17.7242 10.3236C18.8858 10.8872 19.5 11.556 19.5 12.25V13.5H16.5V12.25ZM16 3.5C16 5.15386 14.6539 6.5 13 6.5C12.7399 6.5 12.4897 6.46454 12.2485 6.39868C12.7273 5.53958 13 4.5489 13 3.5C13 2.4511 12.7273 1.46042 12.2485 0.601322C12.4897 0.535458 12.7399 0.5 13 0.5C14.6539 0.5 16 1.84614 16 3.5Z" fill={stroke} />
    </svg>
  );
}

export default Dashboard;
