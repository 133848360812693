import React, { useState, useRef, useEffect } from 'react';

import { Box } from '@mui/material';

import Styled from './ProgressChart.styles';

import { COLORS } from '@/utils/colors';
import {
  ProgressState,
  getProgressColor,
  getStatusColor,
  getStatusText,
} from '@/utils/progressUtils';

interface ProgressChartProps {
  enrollment: ProgressState;
  levelProgress: [number, ProgressState][];
  patientStatus: string;
}

function TitleBox({ title }: { title: string }) {
  return (
    <Box marginBottom="24px">
      <Styled.ProgressTitleText>
        {title}
      </Styled.ProgressTitleText>
    </Box>
  );
}

function StatusBox({ patientStatus } : { patientStatus: string }) {
  const tagColor = getStatusColor(patientStatus);
  const tagText = getStatusText(patientStatus);
  return (
    <Box display="inline-flex" alignItems="center" marginBottom="12px">
      <Box
        bgcolor={tagColor}
        height="12px"
        width="12px"
        borderRadius="6px"
        margin="0 5px 0 0"
      />
      <Styled.StatusText fill={COLORS.providerPortalBlack2}>
        {tagText}
      </Styled.StatusText>
    </Box>
  );
}

function ProgressBox({ enrollment, levelProgress }: {
  enrollment: ProgressState;
  levelProgress: [number, ProgressState][];
}) {
  const [width, setWidth] = useState<number>(750);
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleWindowResize = () => (
      setWidth(divRef.current.offsetWidth)
    );
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => (
      window.removeEventListener('resize', handleWindowResize)
    );
  }, []);

  const enrollmentSpacing = 0.1 * width;
  const enrollmentWidth = enrollmentSpacing - 4;
  const levelProgressSpacing = (0.9 * width) / levelProgress.length;
  const levelProgressWidth = levelProgressSpacing - 4;

  return (
    <div ref={divRef}>
      <Box>
        <svg width="100%" height="55" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width={enrollmentWidth} height="8" fill={getProgressColor(enrollment)} rx="4" ry="4" y="12" />
          {levelProgress.map((progress, index) => (
            <rect
              key={`progress-${progress[0]}`}
              width={levelProgressWidth}
              height="8"
              fill={getProgressColor(progress[1])}
              rx="4"
              ry="4"
              x={enrollmentSpacing + (index * levelProgressSpacing)}
              y="12"
            />
          ))}
          <g x="0" y="50">
            <Styled.ProgressLabelText
              width={enrollmentWidth}
              x={4}
              y="50"
            >
              Enrolled
            </Styled.ProgressLabelText>
            {levelProgress.map((progress, index) => (
              <Styled.ProgressLabelText
                key={`label-${progress[0]}`}
                width={levelProgressWidth}
                x={4 + enrollmentSpacing + (index * levelProgressSpacing)}
                y="50"
              >
                Level&nbsp;
                {progress[0]}
              </Styled.ProgressLabelText>
            ))}
          </g>
        </svg>
      </Box>
    </div>
  );
}

export function ProgressChart({
  enrollment,
  levelProgress,
  patientStatus,
}: ProgressChartProps) {
  return (
    <Box>
      <TitleBox title="Program progress" />
      <StatusBox patientStatus={patientStatus} />
      <ProgressBox enrollment={enrollment} levelProgress={levelProgress} />
    </Box>
  );
}

export default ProgressChart;
