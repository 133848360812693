import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { Memo, Text, Box } from '@bighealth/react-limbix-ui';
import isEqual from 'react-fast-compare';

import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import SortIndicator from './SortIndicator';

import {
  Column,
  SortState,
} from '@/types';
import { COLORS } from '@/utils/colors';

const Header = styled.thead`
  position: sticky;
  top: -1px;
`;

const HeaderRow = styled.tr`
`;

const HeaderCell = styled.th<{ width: string }>`
  background: #F3F3F3;
  overflow-wrap: break-word;
  padding: 13px 16px 13px;
  text-align: left;
`;

const renderColumnHeaderText = (column: Column) => (
  <Text fontWeight="700" lineHeight="18px" fontSize="14px" color="#4C4D4F">
    {column.text}
  </Text>
);

type TableHeadProps = {
  columns: Column[];
  onClickSortIndicator: (columnId: string, sortOrder: SortState) => void;
  sortOrder: SortState;
};

const TableHead: React.FC<TableHeadProps> = Memo(({
  columns,
  onClickSortIndicator,
  sortOrder,
}) => {
  const handleClickSortIndicator = useCallback(
    (columnId: string) => () => onClickSortIndicator(columnId, sortOrder),
    [sortOrder],
  );

  return (
    <Header>
      <HeaderRow>
        {columns.map((column) => (
          <HeaderCell key={column.id} width={column.width}>
            <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
              <Box>
                {column.sortable ? (
                  <SortIndicator
                    onClick={handleClickSortIndicator(column.id)}
                    sortDirection={(column.id === sortOrder.columnId && sortOrder.direction) || 'none'}
                  >
                    {renderColumnHeaderText(column)}
                  </SortIndicator>
                ) : renderColumnHeaderText(column)}
              </Box>
              <Box>
                {column.infoTooltip && (
                  <Tooltip
                    style={{
                      alignSelf: 'flex-start',
                    }}
                    title={(
                      <Box maxWidth="225px">
                        <Text as="p" fontSize="16px" color={COLORS.gray1}>
                          {column.infoTooltip}
                        </Text>
                      </Box>
                    )}
                  >
                    <InfoOutlined
                      fontSize="small"
                      htmlColor={COLORS.gray2}
                    />
                  </Tooltip>
                )}
              </Box>
            </Box>
          </HeaderCell>
        ))}
      </HeaderRow>
    </Header>
  );
}, isEqual);

export default TableHead;
