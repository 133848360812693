import React from 'react';
import styled from 'styled-components/macro';
import { Memo } from '@bighealth/react-limbix-ui';
import isEqual from 'react-fast-compare';

import {
  Row,
  Column,
} from '@/types';

const TBody = styled.tbody`
`;

const BodyRow = styled.tr<{ clickable?: boolean, minRowHeight?: string, lastRow?: boolean }>`
  height: ${({ minRowHeight }) => minRowHeight || '100%'};
  ${(props) => !props.lastRow && 'border-bottom: 1px solid #F0F0F0;'}
  :hover {
    ${({ clickable }) => {
    if (clickable) {
      return `
          background-color: rgba(0, 0, 0, 0.04);
          cursor: pointer;
        `;
    }
    return '';
  }};
  }
`;

const BodyCell = styled.td<{ width?: string }>`
  overflow-wrap: break-word;
  z-index: 1201;
  width: ${(props) => props.width};
  vertical-align: middle;
  padding: 13px 16px;
  text-align: left;
  font-size: 16px;
`;

type TableBodyProps = {
  rows: Row[];
  columns: Column[];
  onClickRow?: (rowId: string) => void;
  minRowHeight?: string,
};
const TableBody: React.FC<TableBodyProps> = Memo(({
  rows,
  columns,
  onClickRow,
  minRowHeight,
}) => (
  <TBody>
    {rows.map((row: Row, index) => (
      <BodyRow
        key={row.id}
        clickable={!!onClickRow}
        onClick={() => (onClickRow && onClickRow(row.id))}
        minRowHeight={minRowHeight}
        lastRow={index === rows.length - 1}
      >
        {row.cells.map((cell) => (
          <BodyCell
            key={`${cell.columnId}-${row.id}`}
            width={columns.find((col) => (col.id === cell.columnId))?.width}
          >
            {cell.render instanceof Date ? cell.render.toString() : cell.render}
          </BodyCell>
        ))}
      </BodyRow>
    ))}
  </TBody>
), isEqual);

export default TableBody;
