import React from 'react';
import { Box, Text } from '@bighealth/react-limbix-ui';

import {
  PrimaryButton,
  SecondaryButton,
} from '../Modal.styles';

import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['NUDGE_WARNING'];
  onClose: () => void;
};
function NudgeWarning(props: Props) {
  const {
    onClose,
    payload,
  } = props;

  const {
    header,
    onContinueSendingNudge,
    body,
  } = payload;

  const handleContinueSendingNudge = () => {
    onContinueSendingNudge();
    onClose();
  };

  return (
    <Box width="500px" padding="32px" position="relative">
      <Box display="inline-flex">
        <Box display="block">
          <Text fontWeight="700" fontSize="20px" lineHeight="26px" marginBottom="8px">
            {header}
          </Text>
          <Text fontSize="16px" lineHeight="24px" marginBottom="8px">
            {body}
          </Text>
          <Box display="flex" marginTop="24px">
            <Box margin="0 0 0 auto" display="inline-flex">
              <SecondaryButton onClick={handleContinueSendingNudge}>
                I understand, let&apos;s keep going
              </SecondaryButton>
              <PrimaryButton onClick={onClose}>
                Nevermind, I&apos;ll wait
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default NudgeWarning;
