import React, { useEffect, useState } from 'react';

import { Text } from '@bighealth/react-limbix-ui';

import moment from 'moment';

import StyledNudgeButton from './NudgeButton.styles';

import {
  CareTeamType,
  QueryNudgeOpportunityArgs,
} from '@/apollo/__generated__/graphql';

import { useModal } from '@/hooks/redux';
import { useLazyQuery } from '@/hooks/apollo';
import { NUDGE_OPPORTUNITY_DRAWER_QUERY } from '@/apollo/queries';
import useDrawer from '@/hooks/redux/useDrawer';

type Props = {
  nudgeOpportunityUid: string;
  careteam: CareTeamType;
  patientFirstName: string;
  patientLastName: string;
  onNudgeSuccess?: () => void;
}

function NudgeButton(props: Props) {
  const {
    nudgeOpportunityUid,
    careteam,
    patientFirstName,
    patientLastName,
    onNudgeSuccess,
  } = props;
  const { showModal } = useModal();
  const lastNudgeSentAt = careteam?.lastNudgeSentAt;
  const [shouldWarnAboutNudge, setShouldWarnAboutNudge] = useState(false);
  const { showDrawer } = useDrawer();

  useEffect(() => {
    if (lastNudgeSentAt) {
      const now = moment();
      setShouldWarnAboutNudge(now.diff(moment(lastNudgeSentAt), 'days') <= 3);
    }
  }, [lastNudgeSentAt]);

  const [getNudgeDrawerData] = useLazyQuery<QueryNudgeOpportunityArgs>(
    NUDGE_OPPORTUNITY_DRAWER_QUERY, {
      variables: {
        careteamUid: careteam?.uid,
        nudgeOpportunityUid,
      },
    },
  );

  const onClick = async () => {
    const { data, error } = await getNudgeDrawerData();
    const openDrawer = () => showDrawer('SEND_NUDGE', {
      providerNotification: data?.nudgeOpportunity?.providerNotification,
      messageBody: data?.nudgeOpportunity?.nudgeOpportunityConfig?.nudgeMessages[0].body,
      queryErrorExists: !!error,
      patientFirstName,
      patientLastName,
      nudgeOpportunityUid,
      careteam,
      onSuccess: onNudgeSuccess,
    });
    if (shouldWarnAboutNudge) {
      showModal('NUDGE_WARNING', {
        header: 'A nudge was just sent!',
        body: 'Sending too many nudges at once can be overwhelming to the patient. Are you sure you want to continue?',
        onContinueSendingNudge: () => openDrawer(),
      });
    } else {
      openDrawer();
    }
  };

  return (
    <StyledNudgeButton
      variant="outlined"
      onClick={onClick}
    >
      <Text color="#1F2E81">
        Nudge
      </Text>
    </StyledNudgeButton>
  );
}

export default NudgeButton;
