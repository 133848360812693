import { Button } from '@mui/material';
import styled from 'styled-components/macro';

const StyledNudgeButton = styled(Button)`
  height: 30px;
  width: 64px;
  border-radius: 4px;
  padding: 4px 10px;
  background: #FFFFFF;
  border: 1px solid #ADBBF8;
  :hover {
    background: #F1F4FE;
  }
  :disabled {
    border: #F5F5F5;
    background: #F5F5F5;
  }
`;

export default StyledNudgeButton;
