import React from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import { Link, Box } from '@bighealth/react-limbix-ui';

import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';

import Styled from './ResetPassword.styles';

import { ErrorMessage } from '@/components';
import { ResetPasswordFormType, ResetPasswordFields } from '@/types';

type Props = {
  onSubmit: (formData: ResetPasswordFormType) => Promise<void>;
  errorMessage?: string;
};

const formSchema = yup.object({
  [ResetPasswordFields.EMAIL]: yup.string().email('Please enter a valid email address').trim(),
});
function ResetPassword(props: Props) {
  const { onSubmit, errorMessage } = props;
  const {
    register, handleSubmit, watch, formState: { errors },
  } = useForm<ResetPasswordFormType>({
    resolver: yupResolver(formSchema) as Resolver<ResetPasswordFormType>,
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        error={!!errors.email?.message}
        helperText={errors.email?.message}
        variant="outlined"
        margin="normal"
        type="text"
        required
        fullWidth
        autoFocus
        label="Email Address"
        id={ResetPasswordFields.EMAIL}
        {...register(ResetPasswordFields.EMAIL, { required: true })}
      />
      <Box margin="24px 0px 32px 0px">
        <Link.RouterLink to="/login" underlined>
          return to login
        </Link.RouterLink>
      </Box>
      <ErrorMessage message={errorMessage} placeholderHeight="1.125rem" fontSize="18px" />
      <Styled.SubmitButton
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={!watch('email')?.length}
      >
        Send reset link
      </Styled.SubmitButton>
    </form>
  );
}

export default ResetPassword;
