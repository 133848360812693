import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Router, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import { CssBaseline, Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { EnvironmentSwitcher, LoadingSpinner } from '@bighealth/react-limbix-ui';

import theme from './theme';
import './index.css';

import Drawer from './components/Drawer';

import { client } from '@/apollo';
import store from '@/store';
import history from '@/utils/history';
import { Modal, Toast } from '@/components';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App = React.lazy(() => import('./App'));
const Login = React.lazy(() => import('@/pages/Common/Login'));
const GetSpark = React.lazy(() => import('@/pages/Common/GetSpark'));
const ResetPassword = React.lazy(() => import('@/pages/Common/ResetPassword'));

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://c4e9832bffc44146b74f391bf0f53d3d@o58970.ingest.sentry.io/5954092',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_DEPLOY_ENV,
    release: `provider@${process.env.APP_VERSION}`,
  });
}

function SuspenseLoading() {
  return (
    <Box height="100vh" width="100vw" display="grid" alignContent="space-around" justifyContent="space-around">
      <LoadingSpinner size="150px" />
    </Box>
  );
}

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Provider store={store}>
            <ApolloProvider client={client}>
              <Router history={history}>
                <Suspense fallback={<SuspenseLoading />}>
                  <Switch>
                    <Route path="/login" exact component={Login} />
                    <Route path="/reset-password" exact component={ResetPassword} />
                    <Route path="/get-spark" exact component={GetSpark} />
                    <Route component={App} />
                  </Switch>
                  <Modal />
                  <Drawer />
                  <Toast />
                </Suspense>
              </Router>
              <EnvironmentSwitcher side="left" bottom="65px" />
            </ApolloProvider>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  </React.StrictMode>,
);
