import { HttpLink } from '@apollo/client';

import { envSwitch } from '@/utils/environmentUtils';

const uri = envSwitch({
  development: 'http://localhost:8000/graphql/',
  dev: 'https://cortex.bighealthdev.com/dev/graphql/',
  stage: 'https://cortex.bighealthstage.com/stage/graphql/',
  stage_rx: 'https://cortex.rx.bighealthstage.com/stage/graphql/',
  prod: 'https://cortex.bighealth.com/prod/graphql/',
  pr: 'https://cortex.bighealthdev.com/dev/graphql/',
});

export const httpLink = new HttpLink({
  uri,
  credentials: 'same-origin',
});
