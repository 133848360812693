import { COLORS } from './colors';

/**
 * The status of the patient.
 */
export enum PatientStatus {
  NOT_ENROLLED = 'not_enrolled',
  INACTIVE = 'inactive',
  ACCOUNT_CREATED = 'account_created',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  DISCONTINUED = 'discontinued'
}

/**
 * The progress state of the patient.
 */
export enum ProgressState {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED'
}

/**
 * Returns the color of the patient status.
 *
 * @param patientStatus - The status of the patient.
 * @returns The color of the patient status.
 */
export const getStatusColor = (patientStatus: string) => {
  switch (patientStatus) {
  case PatientStatus.ACCOUNT_CREATED:
  case PatientStatus.ACTIVE:
  case PatientStatus.COMPLETED:
    return COLORS.providerPortalBlue;
  case PatientStatus.DISCONTINUED:
  case PatientStatus.NOT_ENROLLED:
  case PatientStatus.INACTIVE:
  default:
    return COLORS.providerPortalLightGray;
  }
};

/**
 * Returns the text of the patient status.
 *
 * @param patientStatus - The status of the patient.
 * @returns The text of the patient status.
 */
export const getStatusText = (patientStatus: string) => {
  switch (patientStatus) {
  case PatientStatus.NOT_ENROLLED:
    return 'Your patient has not created an account yet';
  case PatientStatus.INACTIVE:
    return 'Your patient is inactive in the program';
  case PatientStatus.ACCOUNT_CREATED:
    return 'Your patient has created an account';
  case PatientStatus.ACTIVE:
    return 'Your patient is active in the program';
  case PatientStatus.COMPLETED:
    return 'Your patient has completed the program';
  case PatientStatus.DISCONTINUED:
    return 'Your patient has discontinued the program';
  default:
    return '';
  }
};

/**
 * Returns the color of the progress state.
 *
 * @param moduleProgress - The progress state of the patient.
 * @returns The color of the progress state.
 */
export const getProgressColor = (moduleProgress: ProgressState): string => {
  switch (moduleProgress) {
  case ProgressState.STARTED:
    return COLORS.providerPortalDarkGray;
  case ProgressState.COMPLETED:
    return COLORS.providerPortalBlue;
  case ProgressState.NOT_STARTED:
  default:
    return COLORS.providerPortalLightGray;
  }
};

/**
 * This logic determines the status based on a number of factors, working backwards
 * based on which status takes precedence over the others.
 *
 * - If there a completedTime, the program is considered completed.
 * - If not, the logic checks to see if the program was discontinued based on a
 * discontinuedTime.
 * - If there is no discontinuedTime, the logic checks to see if the patient never enrolled
 * in the program based on a lack of enrolledTime.
 * - If there is an enrolledTime, the logic checks to see if there is *no* task data to
 * determine if the patient hasn't started the program.
 * - If there is task data, the logic checks to see if the patient has had any recent activity
 * by checking the totalActiveDays to determine if the patient is active or inactive.
 *
 * @param completedTime - The date when the patient completed the program.
 * @param discontinuedTime - The date when the patient discontinued the program.
 * @param enrolledTime - The date when the patient was enrolled in the program.
 * @param taskDataCount - The number of tasks completed by the patient.
 * @param totalActiveDays - The total number of active days for the patient.
 * @returns The patient status.
 */
export const getPatientStatus = (
  completedTime: Date | null,
  discontinuedTime: Date | null,
  enrolledTime: Date | null,
  taskDataCount: number,
  totalActiveDays: number,
): PatientStatus => {
  if (completedTime) {
    return PatientStatus.COMPLETED;
  }
  if (discontinuedTime) {
    return PatientStatus.DISCONTINUED;
  }
  if (enrolledTime === null) {
    return PatientStatus.NOT_ENROLLED;
  }
  if (taskDataCount === 0) {
    return PatientStatus.ACCOUNT_CREATED;
  }
  return totalActiveDays > 0
    ? PatientStatus.ACTIVE
    : PatientStatus.INACTIVE;
};
