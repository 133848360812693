import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';
import { Memo } from '@bighealth/react-limbix-ui';

import CaretUpSVG from '@/assets/CaretUp.svg';

const CaretUp = styled.img<{ active?: boolean}>`
  height: 9px;
  width: 13px;
  margin-bottom: ${(props) => props.active && '13px'};
`;
const CaretDown = styled(CaretUp)`
  transform: rotate(180deg);
  margin-bottom: 0;
  margin-top: ${(props) => props.active && '13px'};
`;
const IndicatorButton = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  display: flex;
  margin: auto 0 0 0;
  align-items: center;
  padding-left: 0;
`;
const IndicatorContainer = styled.div`
  display: block;
  padding-left: 8px;
`;

type SortIndicatorProps = {
  sortDirection?: 'asc' | 'desc' | 'none';
  onClick: () => void;
};

const areEqual = (prevProps: SortIndicatorProps, nextProps: SortIndicatorProps) => {
  const { sortDirection: prevSortDirection } = prevProps;
  const { sortDirection: nextSortDirection } = nextProps;
  return prevSortDirection === nextSortDirection;
};

const SortIndicator: React.FC<PropsWithChildren<SortIndicatorProps>> = Memo(({
  children,
  sortDirection,
  onClick,
}) => (
  <IndicatorButton
    onClick={onClick}
    type="button"
  >
    {children}
    <IndicatorContainer>
      <div>
        {(sortDirection === 'none' || sortDirection === 'asc') && (
          <CaretUp src={CaretUpSVG} active={sortDirection === 'asc'} />
        )}
      </div>
      <div>
        {(sortDirection === 'none' || sortDirection === 'desc') && (
          <CaretDown src={CaretUpSVG} active={sortDirection === 'desc'} />
        )}
      </div>
    </IndicatorContainer>
  </IndicatorButton>
), areEqual);

export default SortIndicator;
