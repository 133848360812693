/* eslint-disable max-len */
import React, { SVGProps } from 'react';

function Warning(props: Partial<SVGProps<SVGSVGElement>>) {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M10.4621 2.79478L16.6155 13.4527C17.3083 14.6527 16.4423 16.1527 15.0566 16.1527H2.74995C1.36431 16.1527 0.498287 14.6527 1.19111 13.4527L7.34444 2.79477C8.03727 1.59477 9.76932 1.59478 10.4621 2.79478ZM9.31351 3.45793C9.13119 3.14214 8.67539 3.14214 8.49307 3.45793L2.33973 14.1158C2.15741 14.4316 2.38531 14.8264 2.74995 14.8264H15.0566C15.4213 14.8264 15.6492 14.4316 15.4669 14.1158L9.31351 3.45793Z" fill="#DF5F30" />
      <line x1="8.9029" y1="6.82112" x2="8.9029" y2="11.179" stroke="#DF5F30" strokeWidth="1.32632" strokeLinecap="round" />
      <circle cx="8.90291" cy="13.0263" r="0.710526" fill="#DF5F30" />
    </svg>
  );
}

export default Warning;
