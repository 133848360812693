import React from 'react';
import { useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import { Link, Box } from '@bighealth/react-limbix-ui';

import Styled from './Login.styles';

import { ErrorMessage } from '@/components';
import { LoginFormType, LoginFields } from '@/types';

type Props = {
  onSubmit: (formData: LoginFormType) => Promise<void>;
  errorMessage?: string;
};

function Login(props : Props) {
  const { onSubmit, errorMessage } = props;
  const { register, handleSubmit } = useForm<LoginFormType>();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        variant="outlined"
        margin="normal"
        type="text"
        fullWidth
        autoFocus
        required
        autoCapitalize="none"
        label="Email Address"
        id={LoginFields.EMAIL}
        {...register(LoginFields.EMAIL, { required: true })}
      />
      <TextField
        variant="outlined"
        margin="normal"
        type="password"
        fullWidth
        required
        label="Password"
        id={LoginFields.PASSWORD}
        {...register(LoginFields.PASSWORD, { required: true })}
      />
      <Link.RouterLink to="/reset-password" underlined>
        Forgot password?
      </Link.RouterLink>
      <Styled.SubmitButton
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
      >
        Sign in
      </Styled.SubmitButton>
      <Box textAlign="center">
        <ErrorMessage message={errorMessage} />
      </Box>
      <Box>
        By logging into the Provider Portal you acknowledge receipt of the
        {' '}
        {/* TODO: Add new instructions here */}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://documents.limbix.com/provider-portal-instructions.pdf"
          style={{
            color: 'blue',
          }}
        >
          Instructions for Use
        </a>
      </Box>
      <Box marginTop="16px">
        If you&apos;ve forgotten or changed your email address, please email
        {' '}
        <a href="mailto:clinic@bighealth.com">clinic@bighealth.com</a>
      </Box>
    </form>
  );
}

export default Login;
