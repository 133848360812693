import React from 'react';
import { Text, Box } from '@bighealth/react-limbix-ui';

import { tryCatch } from '@/utils/functional';

type Props = {
  message: string,
  fontSize?: string,
  whiteSpace?: string,
  placeholderHeight?: string,
}
function ErrorMessage(props: Props) {
  const {
    message,
    fontSize,
    whiteSpace,
    placeholderHeight,
  } = props;
  if (!message) {
    // If no message passed, check for placeholderHeight to prevent UI from shrinking/growing in weird ways
    return placeholderHeight ? <Box height={placeholderHeight} /> : null;
  }

  // Try and parse the error message out out a stringified array, otherwise return message
  const { result: sanitizedErrorMessage } = tryCatch(() => {
    const matchResults = message.match(/\['(.+)'\]/);
    return matchResults?.length > 0 ? matchResults[1] : message;
  });

  // Add ⚠ icon infront of error message, better than SVG since it scaled automatically with the text
  const errorMessageWithIcon = `⚠ ${sanitizedErrorMessage ?? message}`;

  return (
    <Text color="#DF5F30" fontSize={fontSize} as="div">
      <Box display="inline-block" whiteSpace={whiteSpace}>
        {errorMessageWithIcon}
      </Box>
    </Text>
  );
}

export default ErrorMessage;
