/* eslint-disable max-len */
import React, { SVGProps } from 'react';

function Logout(props: Partial<SVGProps<SVGSVGElement>>) {
  const { width, height, stroke } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        d="M11.5 19.5H8.167A1.667 1.667 0 0 1 6.5 17.833V6.167A1.667 1.667 0 0 1 8.167 4.5H11.5M17.333 16.167 21.5 12l-4.167-4.167M21.5 12h-10"
        strokeWidth={1.42}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Logout;
