import React, { useState } from 'react';
import {
  useIdleTimer, withIdleTimer,
} from 'react-idle-timer';
import { useSelector } from 'react-redux';

import { IdleTimerPayloadType } from '@/types';
import { RootState } from '@/store';
import { useModal } from '@/hooks/redux';

function IdleTimerComponent() : null {
  return null;
}

type Props = {
  idleDuration: number;
  countdownDuration: number;
  onLogout: () => Promise<void> | void;
  devMode?: boolean;
};

function IdleTimer(props: Props) {
  const {
    idleDuration,
    countdownDuration,
    onLogout,
    devMode,
  } = props;
  const { showModal, hideModal } = useModal();
  const visible = useSelector((state: RootState) => state.modal.visible);

  const [fireDispatch, setFireDispatch] = useState<boolean>(null);

  const onIdle = () => {
    if (!visible) {
      setFireDispatch(true);
    }
  };

  const checkToken = () => {
    if (!localStorage.getItem('token')) {
      onLogout();
    }
  };

  const {
    isLeader,
    pause,
    reset,
  } = useIdleTimer({
    timeout: idleDuration,
    onIdle,
    onAction: () => {
      reset();
      checkToken();
    },
    eventsThrottle: 500,
    crossTab: true,
    debounce: 500,
  });

  const stayActive = () => {
    checkToken();
    reset();
  };

  React.useEffect(() => {
    if (devMode) {
      return;
    }

    if (fireDispatch) {
      const payload: IdleTimerPayloadType = {
        countdownDuration,
        stayActive,
        isLeader,
        pause,
        onLogout,
      };
      hideModal();
      showModal('IDLE_WARNING', payload);
      setFireDispatch(false);
    }
  }, [fireDispatch, devMode]);

  if (devMode) {
    return null;
  }

  const IdleTime = withIdleTimer(IdleTimerComponent);

  return <IdleTime />;
}

export default IdleTimer;
