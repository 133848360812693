import { gql } from './__generated__/gql';

export const ASSESSMENT_QUERY = gql(`
  query assessment($assessmentUid: String!) {
    assessment(assessmentUid: $assessmentUid) {
      uid
      name
      introText
      contextText
      preQuestionText
      questions {
        uid
        text
        answers {
          uid
          text
          assessmentValue
        }
      }
    }
  }
`);

export const CARETEAM_QUERY = gql(`
  query careteam($careteamUid: String!, $pdtUid: String!) {
    careteam(careteamUid: $careteamUid) {
      uid
      createdAt
      status
      invitedTime
      enrolledTime
      completedTime
      discontinuedTime
      lastNudgeSentAt
      patient {
        user {
          firstName
          lastName
          dateOfBirth
          gender
          email
          phone
        }
        uid
        emergencyContactName
        emergencyContactPhone
      }
      pdt {
        uid
        name
      }
      userPdtData {
        lastEngagementTime
        currentModuleNumber
        currentTaskNumber
        appAccessDurationDays
        minutesSpentInApp
        lastAccessCodeSentAt
        moodLogs {
          uid
        }
        behavioralActivationTasks {
          uid
          completeTime
          didCompleteScheduledActivation
        }
      }
      assignments {
        completeTime
        visibleDate
        participantAssessmentResult {
          score
          createdAt
          assessmentResultItems {
            assessmentValue
            question {
              uid
            }
          }
          assessment {
            name
            questions {
              uid
            }
          }
        }
      }
    }
    careTeamNudgeHistory(careteamUid:$careteamUid) {
      uid
      header
      body
      sentAt
    }
    practitionerNudgesTable(pdtUid: $pdtUid, careteamUid: $careteamUid) {
      reason
      patientFirstName
      patientLastName
      category
      uid
      triggeredAt
      providerNotification
    }
  }
`);

export const LIBRARY_ITEMS_QUERY = gql(`
  query QueryLibraryItems{
    libraryItems {
        title
        link
        category
        subcategory
    }
  }
`);

export const NUDGE_OPPORTUNITY_DRAWER_QUERY = gql(`
  query NudgeOpportunity($careteamUid: String!, $nudgeOpportunityUid: String!) {
    nudgeOpportunity(careteamUid: $careteamUid, nudgeOpportunityUid: $nudgeOpportunityUid){
      uid
      providerNotification
      nudgeOpportunityConfig {
        nudgeMessages {
          body(nudgeOpportunityUid: $nudgeOpportunityUid)
          header(nudgeOpportunityUid: $nudgeOpportunityUid)
        }
      }
    }
  }
`);

export const PATIENT_PROGRESS_QUERY = gql(`
  query patientProgress($patientUid: String!, $pdtUid: String!) {
    careteam(patientUid: $patientUid, pdtUid: $pdtUid) {
      enrolledTime
      completedTime
      discontinuedTime
      totalActiveDays
    }
    patientLastTasksData(patientUid: $patientUid, pdtUid: $pdtUid) {
      startTime
      completeTime
      moduleNumber
      taskNumber
    }
  }
`);

export const PRACTITIONER_DASHBOARD_TABLE_QUERY = gql(`
  query PractitionerDashboardTable($pdtUids: [String]!, $limit: Int, $offset: Int, $orderBy: [String]) {
    practitionerDashboardTable(pdtUids: $pdtUids, limit: $limit, offset: $offset, orderBy: $orderBy) {
      uid
      total
      patientFirstName
      patientLastName
      patientDateOfBirth
      patientProgramName,
      patientProgramOrderDate,
      totalActiveDaysOfWeek,
      completedPhqCount
      baselinePhqScore
      baselineQuestionsAnswered
      recentPhqScore
      recentQuestionsAnswered
      programStatusText
      programStatusDate
    }
  }
`);

export const PRACTITIONER_INFO_QUERY = gql(`
  query practitioner {
    practitioner {
      uid
      isAdmin
      practitionerRole
      portalAccessTime
      accessCodes {
        pdt{
        name
        uid
        }
      }
      user {
        firstName
        lastName
        localTimezone
      }
      organization {
         isLimbix
         name
      }
    }
  }
`);

export const PRACTITIONER_NUDGES_TABLE_QUERY = gql(`
  query PractitionerNudgesTable($pdtUid: String!, $careteamUid: String, $limit: Int, $offset: Int, $orderBy: [String]) {
    practitionerNudgesTable(
      pdtUid: $pdtUid, careteamUid: $careteamUid, limit: $limit, offset: $offset, orderBy: $orderBy) {
        total
        uid
        patientFirstName
        patientLastName
        patientDateOfBirth
        reason
        category
        triggeredAt
        providerNotification
        careTeam {
          uid
          lastNudgeSentAt
        }
    }
  }
`);

export const PRACTITIONER_QUERY = gql(`
  query practitionerInfo {
    practitioner {
      uid
      practitionerRole
      portalAccessTime
      isAdmin
      user {
        firstName
        lastName
      }
      organization {
         name
      }
    }
  }
`);

export const WEEKLY_PATIENT_ENGAGEMENT_QUERY = gql(`
  query WeeklyPatientEngagementTable($patientUid: String!, $pdtUid: String!) {
    weeklyEngagementTable(patientUid: $patientUid, pdtUid: $pdtUid) {
      programProgress,
      averageSleepEfficiency,
      averageRestLevel,
      totalActiveDaysOfWeek
    }
  }
`);
