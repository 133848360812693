import React, { useEffect, useCallback } from 'react';
import {
  Control, Resolver, useForm, useWatch,
} from 'react-hook-form';
import {
  TextField,
  Divider,
  InputAdornment,
  TextFieldProps,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from '@mui/material';
import { Text } from '@bighealth/react-limbix-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

import Styled from './EnrollPatient.styles';

import { PdtType } from '@/apollo/__generated__/graphql';

import ErrorMessage from '@/components/ErrorMessage';
import { EnrollPatientFields, EnrollPatientFormType, GraphQLErrorsType } from '@/types';
import { DATE_FORMAT_REGEXP, VALID_DATE_REGEXP } from '@/utils/constants';
import { useModal } from '@/hooks/redux';

type SubmitButtonProps = {
  control: Control<EnrollPatientFormType>,
}
function SubmitButton(props: SubmitButtonProps) {
  const { control } = props;
  const formData = useWatch({
    control,
  });
  // Check all required fields are non-null (filled)
  const requiredFieldsFilled = Object.entries(formData).every(([key, value]) => (
    key === 'phone' || !!value
  ));

  return (
    <Styled.SubmitButton
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      disabled={!requiredFieldsFilled}
    >
      Enroll Patient
    </Styled.SubmitButton>
  );
}

// Client-side validation using Yup for the form for easier processing and error handling
const nameValidation = string()
  .matches(/^(\D*)$/, 'Please only enter alphabetical letters')
  .max(255, 'Please enter a string with fewer than 255 characters');
const validationSchema = object().shape({
  firstName: nameValidation.required(''),
  lastName: nameValidation.required(''),
  dateOfBirth: string().required('')
    .matches(DATE_FORMAT_REGEXP, 'Please only enter numbers in the MM/DD/YYYY format')
    .matches(VALID_DATE_REGEXP, 'Please enter a valid date'),
  email: string().required('').max(255, 'Please enter a valid email address with less than 255 characters').trim(),
  phone: string().optional(),
  pdtUid: string().optional(),
});

const ERRORS = {
  EXISTING_EMAIL_ERROR: 'email already exists',
  INVALID_EMAIL_ERROR: 'valid email address',
  PATIENT_AGE_ERROR: 'under the age of',
  PHONE_ERROR: 'phone',
};

type FormFieldProps = TextFieldProps & {
  errorMessage: string,
};
const FormField = React.forwardRef<HTMLInputElement, FormFieldProps>((props, ref) => {
  const { errorMessage, ...rest } = props;
  return (
    <Box margin="22px 0px">
      <TextField
        variant="outlined"
        size="small"
        margin="none"
        type="text"
        fullWidth
        autoCapitalize="none"
        InputLabelProps={{ shrink: true }}
        {...rest}
        ref={ref}
      />
      <ErrorMessage message={errorMessage} placeholderHeight="10px" fontSize="12px" />
    </Box>
  );
});

type Props = {
  onSubmit: (formData: EnrollPatientFormType) => Promise<void> | void;
  submitSuccess: boolean;
  errors: GraphQLErrorsType;
  pdts: PdtType[];
};
function EnrollPatient(props : Props) {
  const {
    onSubmit,
    submitSuccess,
    errors: mutationErrors,
    pdts,
  } = props;
  const { showModal } = useModal();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setFocus,
    setError,
    formState: {
      errors,
    },
  } = useForm<EnrollPatientFormType>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema) as Resolver<EnrollPatientFormType>,
    defaultValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      phone: '',
      pdtUid: pdts[0]?.uid || '',
    },
  });

  const handleExistingEmailError = useCallback(() => {
    setError(EnrollPatientFields.EMAIL, { message: 'User with this email already exists' });
    return EnrollPatientFields.EMAIL;
  }, []);

  const handleInvalidEmailError = useCallback(() => {
    setError(EnrollPatientFields.EMAIL, { message: 'Please enter a valid email address' });
    return EnrollPatientFields.EMAIL;
  }, []);

  const handlePatientAgeError = useCallback((errorMessage: string) => {
    setError(EnrollPatientFields.DATE_OF_BIRTH, { message: errorMessage });
    return EnrollPatientFields.DATE_OF_BIRTH;
  }, []);

  const handlePatientPhoneError = useCallback(() => {
    setError(EnrollPatientFields.PHONE, { message: 'Please enter a valid phone number' });
    return EnrollPatientFields.PHONE;
  }, []);

  const handleUnexpectedError = useCallback(() => {
    showModal('GENERIC_WARNING', {
      header: 'Something went wrong',
      height: '220px',
      width: '350px',
      body: (
        <Text>
          Please contact
          {' '}
          <a href="mailto:clinic@bighealth.com">clinic@bighealth.com</a>
          {' '}
          for help
        </Text>
      ),
    });
  }, []);

  const handleErrorExists = useCallback((errorMessage: string) => {
    const normalizedErrorMessage = errorMessage.toLowerCase();
    if (normalizedErrorMessage.includes(ERRORS.EXISTING_EMAIL_ERROR)) {
      return handleExistingEmailError();
    }
    if (normalizedErrorMessage.includes(ERRORS.INVALID_EMAIL_ERROR)) {
      return handleInvalidEmailError();
    }
    if (normalizedErrorMessage.includes(ERRORS.PATIENT_AGE_ERROR)) {
      return handlePatientAgeError(errorMessage);
    }
    if (normalizedErrorMessage.includes(ERRORS.PHONE_ERROR)) {
      return handlePatientPhoneError();
    }
    return handleUnexpectedError();
  }, [
    handleExistingEmailError,
    handleInvalidEmailError,
    handlePatientAgeError,
    handlePatientPhoneError,
    handleUnexpectedError,
  ]);

  useEffect(() => {
    const errorMessage = mutationErrors?.length && mutationErrors[0].message;
    if (errorMessage?.length) {
      const focusField = handleErrorExists(errorMessage);
      // Short timeout before focusing to ensure rendering has completed
      if (focusField) {
        setTimeout(() => {
          setFocus(focusField);
        }, 100);
      }
    }
  }, [mutationErrors]);

  useEffect(() => {
    if (submitSuccess) {
      reset();
    }
  }, [submitSuccess]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box paddingTop="25px">
        <Text as="h1" fontSize="24px">
          Enroll New Patient
        </Text>
        <Text color="#4C4D4F" marginBottom="5px">
          Choose the program you would like to enroll a patient into.
        </Text>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="pdt-select-label">Program</InputLabel>
          <Select
            variant="standard"
            labelId="pdt-select-label"
            id={EnrollPatientFields.PDT}
            {...register(EnrollPatientFields.PDT)}
            defaultValue={pdts[0]?.uid || ''}
          >
            {pdts.map((pdt) => (<MenuItem value={pdt.uid} key={pdt.uid}>{pdt.name}</MenuItem>))}
          </Select>
        </FormControl>
        <Text color="#4C4D4F" marginBottom="5px">
          Enter your patient’s information to give them access
          {' '}
          to the program and monitor their progress in this portal.
        </Text>
        <FormField
          required
          autoFocus
          label="First name"
          id={EnrollPatientFields.FIRST_NAME}
          {...register(EnrollPatientFields.FIRST_NAME)}
          errorMessage={errors?.firstName?.message}
        />
        <FormField
          required
          label="Last name"
          id={EnrollPatientFields.LAST_NAME}
          {...register(EnrollPatientFields.LAST_NAME)}
          errorMessage={errors?.lastName?.message}
        />
        <FormField
          required
          autoCapitalize="none"
          label="Date of birth"
          placeholder="MM/DD/YYYY"
          id={EnrollPatientFields.DATE_OF_BIRTH}
          {...register(EnrollPatientFields.DATE_OF_BIRTH)}
          errorMessage={errors?.dateOfBirth?.message}
        />
        <Box margin="20px 0px">
          <Divider />
        </Box>
        <Text as="h2" fontSize="24px" fontWeight="600">
          Patient Contact Information
        </Text>
        <Text color="#4C4D4F" fontSize="14px" marginBottom="5px">
          This contact information will be used to send your patient instructions to download and use the program.
          .
        </Text>
        <FormField
          required
          autoCapitalize="none"
          label="Email"
          id={EnrollPatientFields.EMAIL}
          {...register(EnrollPatientFields.EMAIL)}
          errorMessage={errors?.email?.message}
        />
        <FormField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Text fontSize="1rem">
                  🇺🇸
                </Text>
              </InputAdornment>
            ),
          }}
          autoCapitalize="none"
          label="Mobile phone number (optional)"
          id={EnrollPatientFields.PHONE}
          {...register(EnrollPatientFields.PHONE)}
          errorMessage={errors?.phone?.message}
        />
        <SubmitButton control={control} />
      </Box>
    </form>
  );
}

export default EnrollPatient;
