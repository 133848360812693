import React from 'react';
import { Box, Text } from '@bighealth/react-limbix-ui';

type Props = {
  graphicUri: string,
  graphicAlternateText: string,
  header: string,
  body: string,
  button?: React.ReactNode
}

function TableEmptyState(props: Props) {
  const {
    graphicUri,
    graphicAlternateText,
    header,
    body,
    button,
  } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
      height="100%"
      alignItems="center"
      marginTop="50px"
      marginBottom="50px"
    >
      <img height="200px" src={graphicUri} alt={graphicAlternateText} />
      <Text as="h3" fontSize="25px" lineHeight="30px" marginBottom="12px" fontWeight={700}>{header}</Text>
      <Box maxWidth="40%" marginBottom="24px">
        <Text as="p" textAlign="center" fontSize="16px" lineHeight="24px" fontWeight={400}>
          {body}
        </Text>
      </Box>
      {button}
    </Box>
  );
}

export default TableEmptyState;
