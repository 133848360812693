import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Divider, ListItemText } from '@mui/material';
import { Box, Text } from '@bighealth/react-limbix-ui';

import styled from 'styled-components/macro';

import Styled from './SideMenu.styles';

import { AppVersion } from '@/components';
import { useModal } from '@/hooks/redux';
import {
  Dashboard,
  Logout,
  //  Mail,
} from '@/icons';
import { COLORS } from '@/utils/colors';
// import usePaginatedNudges from '@/hooks/apollo/queries/usePaginatedNudges';
import usePaginatedPatients from '@/hooks/apollo/queries/usePaginatedPatients';

const BottomLink = styled.a`
  text-align: center;
  font-size=12px;
  color: #1F2E81;
  line-height: 24px;
`;

type Props = {
  onLogout: () => void;
};
function SideMenu(props: Props) {
  const { onLogout } = props;
  const { showModal } = useModal();
  const location = useLocation();
  let currentLocationPath = `/${location.pathname.split('/')[1]}`;

  const numberOfParams = currentLocationPath.match(/[/]/)?.length ?? 0;

  if (numberOfParams === 1) {
    const isUidParam = currentLocationPath.match(/^[/].*[0-9]+[^/]+$/)?.length ?? 0;
    currentLocationPath = isUidParam ? '/' : currentLocationPath;
  }
  // const nudgesData = usePaginatedNudges();
  const patientsData = usePaginatedPatients();

  const menuList = [
    {
      text: 'Patients',
      linkTo: '/',
      icon: Dashboard,
      margin: true,
      numberOfItems: patientsData?.data?.length > 0 ? patientsData?.data[0].total : 0,
    },
    // TODO: Uncomment when nudges are implemented
    // {
    //   text: 'Nudges',
    //   linkTo: '/nudges',
    //   icon: Mail,
    //   numberOfItems: nudgesData?.data?.length > 0 ? nudgesData?.data[0].total : 0,
    // },
    // {
    //   text: 'Library',
    //   linkTo: '/library',
    //   icon: PaperStack,
    // },
  ];

  const handleClickLogout = () => {
    showModal('CONFIRMATION_POPUP', {
      header: 'Are you sure you want to log out?',
      body: 'This will end your current session.',
      confirmButtonText: 'Log out',
      cancelButtonText: 'Continue Session',
      onConfirm: () => onLogout(),
    });
  };

  return (
    <Styled.Drawer variant="permanent">
      <Divider />
      <Styled.List>
        {
          menuList.map(({
            linkTo,
            margin,
            icon: Icon,
            text,
            numberOfItems,
          }) => {
            const isSelected = linkTo === currentLocationPath;
            return (
              <RouterLink to={linkTo} key={linkTo} className="no-text-decoration">
                <Styled.PortalTab
                  selected={isSelected}
                  $marginTop={margin}
                >
                  <Box width="24px" margin="0px 10px" display="inline-flex" alignItems="center">
                    <Icon
                      height={24}
                      width={24}
                      stroke={isSelected ? COLORS.primary1 : COLORS.gray1}
                      color={isSelected ? COLORS.primary1 : COLORS.gray1}
                    />
                  </Box>
                  <ListItemText>
                    <Text
                      fontWeight={isSelected ? '700' : '400'}
                      color={isSelected ? COLORS.primary1 : COLORS.gray1}
                    >
                      {text}
                    </Text>
                  </ListItemText>
                  <Text color={isSelected ? COLORS.primary1 : COLORS.gray1}>
                    {numberOfItems > 0 ? numberOfItems : ''}
                  </Text>
                </Styled.PortalTab>
              </RouterLink>
            );
          })
        }
        <Styled.PortalTab className="no-text-decoration" onClick={handleClickLogout}>
          <Box width="24px" margin="0px 10px" display="inline-flex" alignItems="center">
            <Logout height={24} width={24} stroke={COLORS.gray1} />
          </Box>
          <ListItemText primary="Logout" />
        </Styled.PortalTab>
      </Styled.List>
      <Box justifyContent="center" display="flex">
        <Box position="absolute" bottom="16px">
          <BottomLink
            target="_blank"
            href="https://privacy.bighealth.com/policies"
            rel="noreferrer"
          >
            Privacy Policy
          </BottomLink>
          <AppVersion />
        </Box>
      </Box>
    </Styled.Drawer>
  );
}

export default SideMenu;
