import {
  OperationVariables,
  DocumentNode,
  useQuery as apolloUseQuery,
} from '@apollo/client';

import { Query } from '@/apollo/__generated__/graphql';
import { QueryResult, QueryHookOptions } from '@/types';

/**
 * @deprecated
 * Use the useQuery directly from apollo client
 * @param query
 * @param options
 * @returns
 */
const useQuery = <T extends OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<T | OperationVariables>,
): QueryResult<T | OperationVariables> => apolloUseQuery<Query, T | OperationVariables>(query, options);

export default useQuery;
