import { IconButton, Box } from '@mui/material';
import { useSelector } from 'react-redux';

import React, { useState } from 'react';

import { useQuery, useMutation } from '@apollo/client';

import { RootState } from '@/store';
import { Close } from '@/icons';
import EnrollPatientForm from '@/components/Form/EnrollPatient';
import usePaginatedPatients from '@/hooks/apollo/queries/usePaginatedPatients';
import { EnrollPatientFormType, GraphQLErrorsType } from '@/types';
import { ENROLL_PATIENT } from '@/apollo/mutations';

import { PDT_UID } from '@/utils/constants';
import { PRACTITIONER_INFO_QUERY } from '@/apollo/queries';

type Props = {
    onClose: () => void;
  };

function EnrollPatientDrawer(props: Props) {
  const {
    onClose,
  } = props;
  const allowedPDTNames = useSelector((state: RootState) => state.providerUser.AllowedPDTNames);
  const pdtUids = allowedPDTNames?.map((pdt) => pdt.uid);
  const { refetch } = usePaginatedPatients(pdtUids);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const { data: practitionerData } = useQuery(PRACTITIONER_INFO_QUERY);
  const [enrollPatientMutation] = useMutation(
    ENROLL_PATIENT,
  );
  const [errors, setErrors] = useState<GraphQLErrorsType>();
  // Default to Spark direct to avoid breaking changes
  const handleSubmit = async (formData: EnrollPatientFormType) => {
    const {
      email,
      firstName,
      lastName,
      phone,
      dateOfBirth,
      pdtUid,
    } = formData;
    const { data, errors: resultErrors } = await enrollPatientMutation({
      variables: {
        email,
        firstName,
        lastName,
        phone: phone || null,
        dateOfBirth: new Date(dateOfBirth),
        pdtUid: pdtUid || PDT_UID,
      },
    });

    if (resultErrors?.length) {
      setErrors(resultErrors as GraphQLErrorsType);
    } else if (data?.enrollPatient?.success) {
      setSubmitSuccess(true);
      refetch();
      onClose();
    }
  };

  return (
    <Box width="min(420px, 100vw)">
      <Box width="48px" height="48px" style={{ float: 'right' }}>
        <IconButton aria-label="close drawer" onClick={onClose} size="large">
          <Close width="24px" height="24px" />
        </IconButton>
      </Box>
      <Box padding="40px">
        <EnrollPatientForm
          onSubmit={handleSubmit}
          errors={errors}
          submitSuccess={submitSuccess}
          pdts={practitionerData.practitioner.accessCodes.map(
            (accessCode) => (accessCode.pdt),
          )}
        />
      </Box>
    </Box>
  );
}

export default EnrollPatientDrawer;
