import {
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
} from '@mui/material';
import styled from 'styled-components/macro';

const AppBar = styled(MuiAppBar)`
  background-color: #FEFEFE;
  z-index: 1201;
  position: fixed;
`;

const Toolbar = styled(MuiToolbar)`
  color: black;
  padding: 0;
`;

const LinkContainer = styled.div`
  height: 88px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const Logo = styled.img`
  height: 30px;
  width: 113px;
  border-radius: 0px;
  margin: 0 auto;
  cursor: pointer;
`;

export default {
  AppBar,
  Toolbar,
  LinkContainer,
  Logo,
};
