import { MutationOptions, fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { Mutation, MutationRefreshTokenArgs } from './__generated__/graphql';
import { REFRESH_TOKEN_MUTATION } from './mutations';

import history from '@/utils/history';
import { stringToBase64 } from '@/utils/stringUtils';

import { client } from '.';

const TerminatingErrors = {
  INVALID_TOKEN: 'Invalid token',
  INVALID_REFRESH_TOKEN: 'Invalid refresh token',
  EXPIRED_REFRESH_TOKEN: 'Refresh token is expired',
  INVALID_CREDENTIALS: 'Please, enter valid credentials',
  IMPROPER_PERMISSIONS: 'You do not have permission to perform this action',
  ERROR_DECODING_TOKEN: 'Error decoding signature',
  NON_PRACTITIONER_LOGIN: 'You must be a Practitioner to access this information',
};

const RetryErrors = {
  EXPIRED_TOKEN: 'Signature has expired',
};

const messageContainsErrors = (message: string, errors: Record<string, string>) => (
  Object.values(errors).some((error) => message.includes(error))
);

const logUserOut = () => {
  localStorage.clear();
  client.stop();
  client.clearStore()
    .then(() => {
      if (window.location.pathname !== 'login') {
        history.replace('/login', { referrer: window.location.pathname });
      }
    });
};

export const networkErrorLink = onError(({
  networkError,
}) => {
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export const terminatingErrorLink = onError(({
  graphQLErrors,
}) => {
  const hasTerminatingError = graphQLErrors?.some(({ message }) => (
    messageContainsErrors(message, TerminatingErrors)
  ));

  if (hasTerminatingError) {
    logUserOut();
  }
});

const refreshExpiredToken = async (cachedRefreshToken: string) => {
  const mutationOptions: MutationOptions<Mutation, MutationRefreshTokenArgs> = {
    mutation: REFRESH_TOKEN_MUTATION,
    variables: {
      refreshToken: cachedRefreshToken,
    },
  };
  const { data, errors } = await client.mutate(mutationOptions);
  if (errors?.length) {
    return null;
  }
  const { token, refreshToken } = data?.refreshToken || {};
  if (token) {
    localStorage.setItem('token', token);
  }
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  }
  localStorage.setItem('tokenCreatedAt', stringToBase64(`${(new Date()).getTime()}`));
  return token;
};

export const retryErrorLink = onError(({
  graphQLErrors,
  operation,
  forward,
}) => {
  const hasRetryError = graphQLErrors?.some(({ message }) => (
    messageContainsErrors(message, RetryErrors)
  ));

  if (hasRetryError) {
    const cachedRefreshToken = localStorage.getItem('refreshToken');
    if (cachedRefreshToken) {
      const refreshTokenObservable = fromPromise(refreshExpiredToken(cachedRefreshToken));
      return refreshTokenObservable.filter((token) => (
        !!token
      )).flatMap((token) => {
        const previousHeaders = operation.getContext().headers;
        operation.setContext({
          headers: {
            ...previousHeaders,
            authorization: token ? `JWT ${token}` : '',
          },
        });
        return forward(operation);
      });
    }
    logUserOut();
  }
  return null;
});
