import moment from 'moment';

import { TimeUnit } from '@/types';

export const formatDate = (date: Date, format?: string, fallback?: string): string => {
  if (date && moment(date, true).isValid()) {
    return moment(date).format(format || 'MMM D YYYY, h:mm A');
  }
  return fallback || '';
};

export const stringToMoment = (date: string): moment.Moment => (
  moment(date, 'MMM D YYYY, h:mm A', true)
);

export const isDate = (value: unknown): boolean => (
  moment(value, moment.ISO_8601, true).isValid()
);

export const toDate = (value: unknown): Date => (
  moment(value, moment.ISO_8601, true).toDate()
);

export const isDateBeforeToday = (date: Date): boolean => (
  new Date(date.toDateString()) <= new Date(new Date().toDateString())
);

export const formatMinsDuration = (duration: number, unit: TimeUnit): number => (
  unit === 'Minutes' ? duration : Math.round(duration / 60)
);

export const getAge = (dateOfBirth: Date) => (
  moment().diff(dateOfBirth, 'years', false)
);
