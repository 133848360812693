enum EnvironmentEnum {
  DEVELOPMENT = 'development',
  DEV = 'dev',
  STAGE = 'stage',
  STAGERX = 'stage_rx',
  PROD= 'prod',
  PR = 'pr',
}

type EnvironmentEnumType = `${EnvironmentEnum}`;

export const envSwitch = (envOptions: Record<EnvironmentEnum, string>, overrideEnv?: EnvironmentEnumType): string => (
  envOptions[overrideEnv ?? process.env.REACT_APP_DEPLOY_ENV as EnvironmentEnum]
);

export const baseURL = process.env.PUBLIC_URL;
