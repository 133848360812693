import React, { useEffect, useMemo } from 'react';
import { Dialog } from '@mui/material';
import { useSelector } from 'react-redux';

import Transition from './Transition';
import IdleWarning from './IdleWarning';
import ConfirmationPopup from './ConfirmationPopup';
import ConfirmationPopupWithMutation from './ConfirmationPopupWithMutation';
import GenericWarning from './GenericWarning';
import NudgeWarning from './NudgeWarning';
import AccessCodeResendPopup from './AccessCodeResendPopup';

import { RootState } from '@/store';
import { ModalContentType } from '@/types';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useActions } from '@/hooks/redux';

type Props<ModalType extends ModalContentType> = {
  payload: ModalPayloadType[ModalType];
  onClose: () => void;
};

const modalTypeToModal: { [key in ModalContentType]: React.FC<Props<key>>} = {
  IDLE_WARNING: IdleWarning,
  CONFIRMATION_POPUP: ConfirmationPopup,
  CONFIRMATION_POPUP_WITH_MUTATION: ConfirmationPopupWithMutation,
  GENERIC_WARNING: GenericWarning,
  NUDGE_WARNING: NudgeWarning,
  ACCESS_CODE_RESEND_POPUP: AccessCodeResendPopup,
  NONE: () => null,
};

function Modal() {
  const { modal: { hideModal } } = useActions();
  const visible = useSelector((state: RootState) => state.modal.visible);
  const modalType = useSelector((state: RootState) => state.modal.modalType);
  const payload = useSelector((state: RootState) => state.modal.payload);

  useEffect(() => () => {
    hideModal();
  }, []);

  // Didn't need to be a function that was called in the JSX, better to use it as a component (since it is)
  const ModalToRender = useMemo(() => (
    modalTypeToModal[modalType] as React.FC<Props<typeof modalType>>
  ), [modalType]);

  if (!visible) {
    return null;
  }

  return (
    <Dialog
      onClose={hideModal as () => void}
      aria-labelledby="dialog"
      open={visible}
      TransitionComponent={Transition}
    >
      <ModalToRender payload={payload} onClose={hideModal} />
    </Dialog>
  );
}

export default Modal;
