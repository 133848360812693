import React from 'react';
import { Box, Text } from '@bighealth/react-limbix-ui';
import { Smartphone } from '@mui/icons-material';
import { Grid } from '@mui/material';

import {
  PrimaryButton,
  SecondaryButton,
} from '../Modal.styles';

import { ModalPayloadType } from '@/types/ReduxTypes';
import { formatPhoneNumber } from '@/utils/stringUtils';

type Props = {
  payload: ModalPayloadType['ACCESS_CODE_RESEND_POPUP'];
  onClose: () => void;
};
function AccessCodeResendPopup(props: Props) {
  const {
    onClose,
    payload,
  } = props;

  const {
    header,
    onResend,
    onEdit,
    phone,
    email,
    cancelButtonText,
    confirmButtonText,
    width,
  } = payload;

  const handleClickResend = () => {
    onResend();
    onClose();
  };

  const handleClickEdit = () => {
    onEdit();
  };

  return (
    <Box width={width ?? '100%'} padding="32px" position="relative">
      <Box display="flex">
        <Box margin="0px 10px" display="flex" alignItems="flex-start">
          <Smartphone />
        </Box>
        <Box display="block">
          <Text fontWeight="700" fontSize="18px" lineHeight="26px" marginBottom="8px">
            {header}
          </Text>
          <Grid>
            <Grid container spacing={1} justifyContent="space-between" alignItems="flex-start">
              <Grid item xs={12} md={12}>
                <Text fontSize="16px">Resend the program enrollment code to patient.</Text>
              </Grid>
              <Grid item xs={12} md={12} />
              <Grid item xs={12} md={12} />
              <Grid item xs={3} md={3}>
                <Text fontSize="16px">Email</Text>
              </Grid>
              <Grid item xs={9} md={9}>
                <Text fontSize="16px">{email}</Text>
              </Grid>
              <Grid item xs={3} md={3}>
                <Text fontSize="16px">Phone</Text>
              </Grid>
              <Grid item xs={9} md={9}>
                <Text fontSize="16px">{formatPhoneNumber(phone)}</Text>
              </Grid>
            </Grid>
          </Grid>
          <Box display="flex" marginTop="24px">
            <Box display="flex" alignItems="right" marginLeft="10%">
              <SecondaryButton onClick={onClose}>
                {cancelButtonText || 'Cancel'}
              </SecondaryButton>
              <SecondaryButton onClick={handleClickEdit} disabled>
                {cancelButtonText || 'Edit details'}
              </SecondaryButton>
              <PrimaryButton onClick={handleClickResend}>
                {confirmButtonText || 'Resend code'}
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AccessCodeResendPopup;
