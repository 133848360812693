import styled from 'styled-components';

import { COLORS } from '@/utils/colors';

const ProgressTitleText = styled.text`
  font-family: GT Walsheim;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  text-anchor: middle;
  color: ${COLORS.providerPortalBlack1};
  fill: ${COLORS.providerPortalBlack1};
`;

const StatusText = styled.text`
  font-family: GT Walsheim;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  text-anchor: middle;
  margin-left: 10px;
  color: ${COLORS.providerPortalBlack2};
  fill: ${COLORS.providerPortalBlack2};
`;

const ProgressLabelText = styled.text`
  font-family: GT Walsheim;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  overflow: hidden;
  text-align: left;
  text-anchor: left;
  text-overflow: ellipsis;
  margin-left: 10px;
  color: ${COLORS.providerPortalBlack3};
  fill: ${COLORS.providerPortalBlack3};
`;

export default {
  ProgressLabelText,
  ProgressTitleText,
  StatusText,
};
