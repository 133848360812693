import React, { useMemo } from 'react';
import { Drawer as MuiDrawer, duration } from '@mui/material';
import { useSelector } from 'react-redux';

import SendNudgeDrawer from './SendNudgeDrawer';
import EnrollPatientDrawer from './EnrollPatientDrawer';

import { RootState } from '@/store';
import { DrawerContentType, DrawerPayloadType } from '@/types/ReduxTypes';
import { useActions } from '@/hooks/redux';
import { usePrefersReducedMotion } from '@/hooks/ui';

type Props<DrawerType extends DrawerContentType> = {
  payload: DrawerPayloadType[DrawerType];
  onClose: () => void;
};

const drawerTypeToDrawer: { [key in DrawerContentType]: React.FC<Props<key>>} = {
  SEND_NUDGE: SendNudgeDrawer,
  ENROLL_PATIENT: EnrollPatientDrawer,
  NONE: () => null,
};

function Drawer() {
  const { drawer: { hideDrawer } } = useActions();
  const visible = useSelector((state: RootState) => state.drawer.visible);
  const drawerType = useSelector((state: RootState) => state.drawer.drawerType);
  const payload = useSelector((state: RootState) => state.drawer.payload);
  const prefersReducedMotion = usePrefersReducedMotion();

  const transitionDuration = prefersReducedMotion ? {
    enter: 0,
    exit: 0,
  } : {
    enter: duration.enteringScreen,
    exit: duration.leavingScreen,
  };

  // Didn't need to be a function that was called in the JSX, better to use it as a component (since it is)
  const DrawerToRender = useMemo(() => (
    drawerTypeToDrawer[drawerType] as React.FC<Props<typeof drawerType>>
  ), [drawerType]);

  if (!visible) {
    return null;
  }

  return (
    <MuiDrawer
      anchor="right"
      open={visible}
      onClose={hideDrawer as () => void}
      transitionDuration={transitionDuration}
    >
      <DrawerToRender payload={payload} onClose={hideDrawer} />
    </MuiDrawer>
  );
}

export default Drawer;
