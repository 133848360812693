import styled from 'styled-components/macro';
import { Button } from '@mui/material';

const SubmitButton = styled(Button)`
  margin-top: 20px;
  background-color: #1F2E81;
  font-weight: 700;
  margin-bottom: 16px;
`;

export default {
  SubmitButton,
};
