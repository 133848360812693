import React from 'react';
import {
  MenuItem,
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFormContext,
  Controller,
} from 'react-hook-form';
import { Text, Box } from '@bighealth/react-limbix-ui';

import Styled from '../GetSpark.styles';

import { US_STATES } from '@/utils/constants';
import { GetSparkFormType, GetSparkFields } from '@/types';

const PROVIDER_POSITIONS = [
  {
    label: 'Pediatric primary care provider',
    value: 'pediatricPrimary',
  },
  {
    label: 'Pediatric psychiatrist',
    value: 'pediatricPsychiatrist',
  },
  {
    label: 'Family medicine',
    value: 'familyMedicine',
  },
  {
    label: 'Not a physician/NP/PA',
    value: 'notPhysician',
  },
];

type Props = {
  loading: boolean;
  onClickBackButton: () => void;
}
function PracticeInformation(props: Props) {
  const { loading, onClickBackButton } = props;
  const {
    register,
    control,
    formState: {
      isValid,
    },
  } = useFormContext<GetSparkFormType>();
  return (
    <>
      <Text as="h1">
        Practice Information
      </Text>
      <TextField
        variant="outlined"
        margin="normal"
        type="text"
        fullWidth
        autoFocus
        label="Organization Name"
        id={GetSparkFields.ORGANIZATION_NAME}
        {...register(GetSparkFields.ORGANIZATION_NAME)}
      />
      <Controller
        name={GetSparkFields.POSITION}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            variant="outlined"
            margin="normal"
            select
            fullWidth
            aria-label="select role"
            id={GetSparkFields.POSITION}
            label="Role"
            defaultValue={value}
            onChange={onChange}
            value={value}
          >
            <MenuItem key="" value="">
              ---
            </MenuItem>
            {PROVIDER_POSITIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <TextField
        variant="outlined"
        margin="normal"
        type="text"
        fullWidth
        required
        label="Street Address"
        id={GetSparkFields.ADDRESS}
        {...register(GetSparkFields.ADDRESS, { required: true })}
      />
      <TextField
        variant="outlined"
        margin="normal"
        type="text"
        fullWidth
        required
        label="City"
        id={GetSparkFields.CITY}
        {...register(GetSparkFields.CITY, { required: true })}
      />
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item sm={6} xs={12}>
          <Controller
            name={GetSparkFields.STATE}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                margin="normal"
                variant="outlined"
                select
                fullWidth
                required
                aria-label="select state"
                id={GetSparkFields.STATE}
                label="State"
                defaultValue={value}
                value={value}
                onChange={onChange}
              >
                <MenuItem key="none" value="">
                  ---
                </MenuItem>
                {US_STATES.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            type="text"
            fullWidth
            required
            label="Postal Code"
            id={GetSparkFields.POSTAL_CODE}
            {...register(GetSparkFields.POSTAL_CODE, { required: true })}
          />
        </Grid>
      </Grid>
      <Box marginTop="20px">
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={6}>
            <Button fullWidth type="button" variant="outlined" onClick={onClickBackButton}>
              Back
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Styled.SubmitButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading || !isValid}
            >
              Submit
            </Styled.SubmitButton>
          </Grid>
        </Grid>
        <Text marginTop="20px">
          By submitting this form, you consent to Big Health processing your data
          or personal information in accordance with our
          {' '}
          <a href="https://privacy.bighealth.com/policies" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          {' and '}
          <a href="https://privacy.bighealth.com/policies?name=terms-of-use" target="_blank" rel="noreferrer">
            Terms of Use
          </a>
          .
        </Text>
      </Box>
    </>
  );
}

export default PracticeInformation;
